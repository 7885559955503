import { AdministratorService } from "company-dashboard/model/dashboard/company";
import { autoinject } from "aurelia-framework";
import { ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";

@autoinject
export class DeactivateAdministrator
{
	private readonly _modal: Modal;
	private readonly _administratorService: AdministratorService;
	private readonly _toastService: ToastService;

	public constructor(
		modal: Modal,
		employeeService: AdministratorService,
		toastService: ToastService)
	{
		this._modal = modal;
		this._administratorService = employeeService;
		this._toastService = toastService;
	}

	protected model: { administratorId: number, administratorFirstName: string };
	protected deactivateOperation: Operation;
	protected administratorDeactivated: boolean = false;

	public activate(model: { administratorId: number, administratorFirstName: string }): void
	{
		this.model = model;
	}

	public deactivate(): boolean
	{
		return this.administratorDeactivated;
	}

	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}

	protected async onDeactivateClick(): Promise<void>
	{
		this.deactivateOperation = new Operation(async signal =>
		{
			try
			{
				await this._administratorService.deactivateAdministrator(this.model.administratorId);

				this.administratorDeactivated = true;

				this._toastService.open(
					"neutral",
					{
						heading: this.model.administratorFirstName,
						body: "blev deaktiveret som administrator.",
						icon: "person--negative"
					}
				)

				this._modal.close();
			} catch (error: any)
			{
				if ([400, 401, 403].includes(error.response.status))
				{
					this._toastService.open(
						"error",
						{
							"message": "Administratoren kunne ikke blive deaktiveret."
						}
					)
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
						}
					)
				}

				console.log("An error occurred.\n", error, signal);
			}
		})
	}
}
