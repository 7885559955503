import { autoinject } from "aurelia-framework";
import { PayrollSystemService } from "company-dashboard/model/dashboard/company";
import { ModalService, ThemeService, ToastService } from "shared/framework";
import { Operation } from "shared/utilities";

@autoinject
export class ConnectToZenegy {
    public constructor(themeService: ThemeService, modalService: ModalService, toastService: ToastService, payrollSystemService: PayrollSystemService) {
        this.themeService = themeService;
        this.modalService = modalService;
        this._toastService = toastService;
        this._payrollSystemService = payrollSystemService;
    }

    protected readonly modalService: ModalService;
    protected readonly themeService: ThemeService;
    private readonly _toastService: ToastService;
    private readonly _payrollSystemService: PayrollSystemService;

    protected zenegyAutorizationUrl?: string;

    protected operation: Operation;

    /**
     * Called by the framework when the modal is activated.
     */
    public async activate(): Promise<void> {
        await this.getZenegyConnectionUrl();
    }

    protected redirectToZenegy(): void {
        location.assign(this.zenegyAutorizationUrl!);
    }

    protected async getZenegyConnectionUrl(): Promise<void> {
        // Create and execute the operation
        this.operation = new Operation(async signal => {
            try {
                const response = await this._payrollSystemService.getZenegyAuthorizationUrl(signal);

                this.zenegyAutorizationUrl = response;
            }
            catch (error: any) {
                if (error.response.status === 400) {
                    this._toastService.open(
                        "error",
                        {
                            "message": "Vi kunne deværre ikke få en nøgle fra Zenegy."
                        }
                    );
                }
                else {
                    this._toastService.open(
                        "error",
                        {
                            "message": error.message
                        }
                    );
                }
            }
        })
    }
}
