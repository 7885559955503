
import { autoinject } from "aurelia-framework";

@autoinject
export class Carousel
{
  constructor() {}

  protected carouselContainer: HTMLDivElement;

  protected sliderFrame: HTMLDivElement;

  protected slides: HTMLCollection;

  protected currentSlideIndex: number = 0;

  attached(): void
	{
    this.initialize();
  }

  detached(): void
	{}

  protected get slideWidth(): number
  {
    return this.carouselContainer.offsetWidth;
  }

  private initialize(): void
  {
    this.slides = this.sliderFrame.children;
    this.onSlideChange();
  }

  protected onNextClick(): void
  {
    if (this.currentSlideIndex < this.slides.length - 1)
    {
      this.currentSlideIndex++;
      this.onSlideChange();
    }
  }

  protected onPreviousClick(): void
  {
    if (this.currentSlideIndex > 0)
    {
      this.currentSlideIndex--;
      this.onSlideChange();
    }
  }

  public onSlideChange(): number
  {
    return this.currentSlideIndex + 1;
  }
}
