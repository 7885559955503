import { AgreementDocument } from "company-dashboard/model/dashboard/company";
import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { SignedDocument, UnsignedDocument } from "..";

/**
 * Represents a service that manages the agreement document page in sign up.
 */
@autoinject
export class AgreementDocumentsService
{
  /**
   * Creates a new instance of the type.
   * @param apiClient The `ApiClient` instance.
   * @param identityService The `IdentityService` instance.
   */
  public constructor(apiClient: ApiClient, identityService: IdentityService)
  {
    this._apiClient = apiClient;
    this._identityService = identityService;
  }

  private readonly _apiClient: ApiClient;
  private readonly _identityService: IdentityService;

  /**
   * Get the agreement documents ready to be read by the administrator.
   * @param signal The abort signal to use, or undefined to use no abort signal.
   * @returns A promise that will be resolved with a list of agreement document data.
   */
  public async getAgreementDocuments(signal?: AbortSignal): Promise<AgreementDocument[]>
  {
    const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/agreement-documents`,
    {
      signal
    });

    return result.data.documents.map(documentData => new AgreementDocument(documentData));
  }

  /**
   * Marks the agreements as having been read in the database.
   * @param signal The abort signal to use, or undefined to use no abort signal.
   */
  public async setDocumentsRead(signal?: AbortSignal): Promise<void>
  {
    await this._apiClient.post(`/${this._identityService.identity.company?.id}/documents/read`,
    {
      body:
      {
        documentsRead: true
      },
      signal
    });
  }

  /**
   * @param signal The abort signal to use, or undefined to use no abort signal.
   */
  public async getDocumentToSign(signal?: AbortSignal): Promise<{ hasUnsignedDocuments: boolean, unsignedDocument?: UnsignedDocument }>
  {
    const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/documents/unsigned`,
    {
      signal
    });

    return {
      hasUnsignedDocuments: result.data.hasUnsignedDocuments,
      unsignedDocument: result.data.unsignedDocument != null ? new UnsignedDocument(result.data.unsignedDocument) : undefined
    }
  }

  /**
   * @param signal The abort signal to use, or undefined to use no abort signal.
   */
  public async getDocumentsSigningUrl(signal?: AbortSignal): Promise<string>
  {
    const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/documents/signing-url`,
    {
      signal
    });

    return result.data.url;
  }

  /**
   * @param signal The abort signal to use, or undefined to use no abort signal.
   */
  public async setSignedDocuments(signal?: AbortSignal): Promise<void>
  {
    await this._apiClient.post(`/${this._identityService.identity.company?.id}/documents/signed`,
    {
      signal
    });
  }

  /**
   * @param signal The abort signal to use, or undefined to use no abort signal.
   */
  public async signDocument(signedDocument: SignedDocument, signal?: AbortSignal): Promise<void>
  {
    await this._apiClient.post(`/${this._identityService.identity.company?.id}/documents/sign`,
    {
      body: signedDocument.toJSON(),
      signal
    });
  }
}
