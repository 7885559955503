import clone from "clone";

export class Administrator
{
	public constructor()
	{
		this.firstName = "";
		this.lastName = "";
		this.email = "";
		this.password = "";
		this.receiveMarketingEmails = false;
	}

	public firstName: string;

	public lastName: string;

	public email: string;

	public password: string

	public receiveMarketingEmails: boolean;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}

	public get fullName(): string
	{
		return `${this.firstName} ${this.lastName}`;
	}
}
