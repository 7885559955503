import clone from "clone";

export class CompanyInitial
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.id = data.company.id;
		this.name = data.company.name;
		this.cvr = data.company.cvr;
		this.documentsSigned = data.signup.documentsSigned;
		this.employeesInvited = data.dashboard.employeesInvited;
		this.hasMandatoryPlan = data.dashboard.hasMandatoryPlan;
		this.firstEmployeePrompted = this.employeesInvited === true ? true : false;
	}

	public readonly id: number;

	public readonly name: string;

	public readonly cvr: string;

	public readonly documentsSigned: boolean;

	public readonly employeesInvited: boolean;

	public readonly hasMandatoryPlan: boolean;

	public firstEmployeePrompted: boolean;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
