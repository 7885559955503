import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { IValidation, ModalService, ToastService } from "shared/framework";
import { IdentityService } from "company-dashboard/services/identity";
import { EmployeeSignUp, EmployeeService } from "company-dashboard/model/dashboard/employee";
import { Operation } from "shared/utilities";

@autoinject
export class PlaceOfResidence
{
	public constructor(
		identityService: IdentityService,
		employeeService: EmployeeService,
		modalService: ModalService,
		toastService: ToastService)
	{
		this.identityService = identityService;
		this._employeeService = employeeService;
		this._modalService = modalService;
		this._toastService = toastService;
	}

	private readonly _employeeService: EmployeeService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	protected readonly identityService: IdentityService;

	protected operation: Operation;

	protected validation: IValidation;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected state: "add" | "edit";

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected employee: EmployeeSignUp;

	@bindable
	protected onNext: () => void;

	@bindable
	protected onBackClick: () => void;

	@bindable
	protected onCloseClick: () => void;

	protected firstLoad: boolean = true;

	protected async employeeChanged(newValue: EmployeeSignUp): Promise<void>
	{
		if (this.firstLoad)
		{
			if (newValue.id != null)
			{
				await this.getAddressInformation();
			}
			this.firstLoad = false;
		}
	}

	protected async getAddressInformation(): Promise<void>
	{
		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				// Fetch the data
				const result = await this._employeeService.getAddressInformation(
					this.employee.id,
					signal
				);

				this.employee = result;

				if (this.employee.fromPayrollSystem)
				{
					this.validation.active = true;
				}
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": `An error occurred.\n${error.message.toString()}`
					}
				)
			}
		})
	}

	protected async onUpdateAddressInformation(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				await this._employeeService.updateAddressInformation(this.employee, signal);

				await this._modalService.closeAll("cancel");

				this._toastService.open(
					"success",
					{
						heading: `Oplysninger for ${this.employee.personalInformation.firstName} er blevet ændret.`
					}
				)
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				);
			}
		})
	}

	protected async onNextClick(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		this.onNext();
	}
}
