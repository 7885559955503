import { EmployeeSimple, EmployeeService } from "company-dashboard/model/dashboard/employee";
import { DateTime } from "luxon";
import { autoinject, computedFrom, bindingMode, bindable } from "aurelia-framework";
import { ModalService, ThemeService, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";
import { SignUpHelp } from "./modals/sign-up-help/sign-up-help";
import { MissingAddressInformationHelp as MissingInformationHelp } from "./modals/missing-information-help/missing-information-help";
import { IdentityService } from "company-dashboard/services/identity";
import { SignUpEmployeeFlow } from "company-dashboard/modals/sign-up-employee-flow/sign-up-employee-flow";
import { EventAggregator } from "aurelia-event-aggregator";
import { ConnectToSalary } from "company-dashboard/modals/connect-to-payroll-system/connect-to-salary/connect-to-salary";
import { ConnectToZenegy } from "company-dashboard/modals/connect-to-payroll-system/connect-to-zenegy/connect-to-zenegy";
import { PayrollSystem } from "company-dashboard/model/dashboard/shared";

/**
 * Represents the module.
 */
@autoinject
export class EmployeesModule extends BaseModule
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor(
		employeeService: EmployeeService,
		identityService: IdentityService,
		toastService: ToastService,
		modalService: ModalService,
		themeService: ThemeService,
		eventAggregator: EventAggregator)
	{
		super();

		this._employeeService = employeeService;
		this.identityService = identityService;
		this._toastService = toastService;
		this._modalService = modalService;
		this.themeService = themeService;
		this._aureliaEventAggregator = eventAggregator;
		this._contructed = true;
	}

	protected readonly identityService: IdentityService;
	protected readonly themeService: ThemeService;
	private readonly _employeeService: EmployeeService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	private readonly _aureliaEventAggregator: EventAggregator;
	private readonly _contructed;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected updatePage: boolean;

	/**
	 * The salary system connected to
	 */
	 protected payrollSystem: PayrollSystem = new PayrollSystem("unknown");

	/**
	 * The items to represent in the table
	 */
	protected employees: EmployeeSimple[] | undefined;

	/**
	 * True if initial loading failed
	 */
	protected failed: boolean = false;

	/**
	 * Called by the framework when the module is activated.
	 */
	public doActivate(): void
	{
		this.update();

		if (
			window.localStorage.key(window.localStorage.length - 1) === "nulstil-adgangskode" ||
			window.localStorage.key(window.localStorage.length - 1) === "ny-administrator")
		{
			this._toastService.open(
				"success",
				{
					heading: "Din adgangskode er nu ændret."
				}
			)
		}

		this._aureliaEventAggregator.subscribe(
			"sign:up:employee:flow",
			() => {
				if (!this.updateOperation.pending)
				{
					this.update();
				}
			}
		)
	}

	protected updatePageChanged(newValue: boolean): void
	{
		if (newValue)
		{
			this.update();
		}
	}

	protected get now(): DateTime
	{
		return DateTime.now();
	}

	protected get connectedToPayrollSystem(): boolean
	{
		return this.payrollSystem.slug !== "none" && this.payrollSystem.slug !== "unknown";
	}

	@computedFrom("employees")
	protected get signedUpWarning(): boolean
	{
		// If information is missing, then the warning about employees not finishing onboarding is not shown
		return this.employees == null || this.missingInformationError
			? false
			: this.employees.filter(employee => employee.status.slug === "signed-up" || employee.status.slug === "in-onboarding").length > 0;
	}

	@computedFrom("employees")
	protected get missingInformationError(): boolean
	{
		return this.employees == null
			? false
			: this.employees.filter(employee => employee.missingPersonalInformation || employee.missingAddressInformation || employee.missingCompensationInformation).length > 0;
	}

	/**
	 * Sorts the current list of employees
	 * @returns a sorted list of employees or undefined, if there is no data
	 */
	@computedFrom("employees")
	protected get sortedEmployees(): EmployeeSimple[] | undefined
	{
		return this.employees?.sort((a: EmployeeSimple, b: EmployeeSimple) =>
		{
			// Deactivated must be furthest down the list, if any
			if (a.status.slug === "deactivated" && b.status.slug !== "deactivated")
			{
				return 1;
			}
			if (a.status.slug !== "deactivated" && b.status.slug === "deactivated")
			{
				return -1;
			}

			// Not invited employees must be furthest down the list (before deactivated if shown)
			if (a.status.slug === "not-invited" && b.status.slug !== "not-invited")
			{
				return 1;
			}
			if (a.status.slug !== "not-invited" && b.status.slug === "not-invited")
			{
				return -1;
			}

			// If two employees have same status, then their order depends on other information
			if(a.status.slug === "signed-up" && b.status.slug === "signed-up")
			{
				if(a.missingPersonalInformation || a.missingAddressInformation || a.missingCompensationInformation)
				{
					return -1;
				}
				if(b.missingPersonalInformation || b.missingAddressInformation || b.missingCompensationInformation)
				{
					return 1;
				}
			}
			if(a.status.slug === "signed-up" && b.status.slug !== "signed-up")
			{
				return -1;
			}
			if(a.status.slug !== "signed-up" && b.status.slug === "signed-up")
			{
				return 1;
			}

			// An employee that is in onboarding must be higher on the list compared
			// to an employee that is onboarded
			if (a.status.slug === "in-onboarding" && b.status.slug === "onboarded")
			{
				return -1;
			}
			if (a.status.slug === "onboarded" && b.status.slug === "in-onboarding")
			{
				return 1;
			}

			return 0;
		})
	}

	protected async onSignUpWarningClick(): Promise<void>
	{
		await this._modalService.open(SignUpHelp).promise;
	}

	protected async onMissingInformationErrorClick(): Promise<void>
	{
		await this._modalService.open(MissingInformationHelp).promise;
	}

	protected async onSignUpEmployeeClick(): Promise<void>
	{
		await this._modalService.open(SignUpEmployeeFlow, { employeeFlow: "add-employee" }).promise;

		this.update();
	}

	protected async openConnectToSalaryModal(): Promise<void>
	{
		await this._modalService.open(ConnectToSalary).promise;

		this.update();
	}

	protected async openConnectToZenegyModal(): Promise<void>
	{
		await this._modalService.open(ConnectToZenegy).promise;

		this.update();
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	protected update(): void
	{
		// Returns if the object is not contructed.
		// This is needed because the `observable` decorator called the change handler when the
		// initial property value is set, which happens before the constructor is called
		if (!this._contructed)
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			this.failed = false;

			try
			{
				// Fetch the data
				const result = await this._employeeService.getEmployees(signal);

				this.payrollSystem = result.payrollSystem;
				this.employees = result.employees;
			}
			catch (error: any)
			{
				this.failed = true;
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				)
			}
		})
	}
}
