import { SignUpEmployeeFlow } from "company-dashboard/modals/sign-up-employee-flow/sign-up-employee-flow";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ModalService } from "shared/framework";
import { DeactivateEmployee } from "../../modals/deactivate-employee/deactivate-employee";
import { IdentityService } from "company-dashboard/services/identity";

@autoinject
export class EmployeeMenu
{
	public constructor(modalService: ModalService, identityService: IdentityService)
	{
		this._modalService = modalService;
		this.identityService = identityService;
	}

	protected readonly identityService: IdentityService;
	private readonly _modalService: ModalService;

	@bindable
	employeeId: number | undefined;
	@bindable
	employeeFirstName: string;
	@bindable
	employeeIsActive: boolean;
	@bindable
	employeeMissingPersonalInformation: boolean;
	@bindable
	employeeMissingAddressInformation: boolean;
	@bindable
	employeeMissingCompensationInformation: boolean;

	@bindable({ defaultBindingMode: bindingMode.fromView })
	protected updatePage: boolean = false;

	protected async deactivateEmployee(): Promise<void>
	{
		if(this.employeeId == null)
		{
			// Employee that has no employee id cannot be deactivated
			return;
		}

		const updated = await this._modalService.open(DeactivateEmployee, { employeeId: this.employeeId, employeeFirstName: this.employeeFirstName }).promise;

		if (updated)
		{
			this.updatePage = true;
		}
	}

	protected async onEditEmployeeClick(flow: "personal-information" | "salary-and-pension" | "place-of-residence"): Promise<void>
	{
		await this._modalService.open(SignUpEmployeeFlow, { employeeFlow: flow, employeeId: this.employeeId, employeeFirstName: this.employeeFirstName }).promise;

		this.updatePage = true;
	}
}
