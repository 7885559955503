import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { PayrollSystemConnection, PayrollSystemResponse } from "../entities/payroll-system-response";
import { PayrollSystem } from "../../shared";

@autoinject
export class PayrollSystemService
{
	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	public constructor(
		apiClient: ApiClient,
		identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	public async getPayrollSystemInformation(signal?: AbortSignal): Promise<PayrollSystemResponse>
	{
		const response = await this._apiClient.get(
			`/${this._identityService.identity.company?.id}/payroll-system`,
			{
				signal
			}
		);

		return new PayrollSystemResponse(response.data);
	}

	public async getZenegyAuthorizationUrl(signal?: AbortSignal): Promise<string>
	{
		let response = await this._apiClient.get(
			`/${this._identityService.identity.company?.id}/payroll-system/zenegy/authorization-url`,
			{
				signal
			}
		);

		return response.data.url;
	}

	public async addPayrollSystemApiToken(apiToken: string, payrollSystem: PayrollSystem, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.company?.id}/payroll-system/add`,
			{
				body: { apiToken: apiToken, payrollSystem: payrollSystem.value },
				signal
			}
		);
	}

	public async removePayrollSystemApiToken(connection: PayrollSystemConnection, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.company?.id}/payroll-system/delete/${connection.id}`,
			{
				signal
			}
		);
	}
}
