import { autoinject } from "aurelia-framework";
import { Modal } from "shared/framework/services/modal";

@autoinject
export class SignUpHelp
{
	public constructor(modal: Modal)
	{
		this._modal = modal;
	}

	private readonly _modal: Modal;

	/**
	 * Called when the cancel button is clicked.
	 */
	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}
}
