import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ModalService } from "shared/framework";
import { DeactivateAdministrator } from "../../modals/deactivate-administrator/deactivate-administrator";

@autoinject
export class AdministratorMenu
{
	private readonly _modalService: ModalService;

	@bindable
	administratorId: number;
	@bindable
	administratorFirstName: string;

	@bindable({ defaultBindingMode: bindingMode.fromView })
	protected updatePage: boolean;

	public constructor(modalService: ModalService)
	{
		this._modalService = modalService;
	}

	protected async deactivateAdministrator(): Promise<void>
	{
		let updated = await this._modalService.open(DeactivateAdministrator, { administratorId: this.administratorId, administratorFirstName: this.administratorFirstName }).promise;
		if (updated)
		{
			this.updatePage = !this.updatePage;
		}
	}
}
