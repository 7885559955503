import clone from "clone";

export class CompanyDetailed
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.name = data.name;
    this.cvrNumber = data.cvrNumber;
  }

  public readonly name: number;

  public readonly cvrNumber: string;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
