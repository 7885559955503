import { autoinject, bindable } from "aurelia-framework";

/**
 * Represents the main cta element on a page
 */
@autoinject
export class PageCta
{
  public constructor()
  {
    this.disabled = false;
  }

  @bindable
  public onClick: () => void;

  @bindable
  public disabled: boolean;
}
