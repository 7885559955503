import { autoinject } from "aurelia-framework";
import { CompanyInitial, IdentityService } from "company-dashboard/services/identity";
import { ModalService, ToastService } from "shared/framework";

@autoinject
export class ChangeCompany
{
  public constructor(
		identityService: IdentityService,
		modalService: ModalService,
		toastService: ToastService,)
  {
		this._identityService = identityService;
		this._modalService = modalService;
		this._toastService = toastService;
  }

	protected readonly _identityService: IdentityService;
	private readonly _modalService: ModalService;
	private readonly _toastService: ToastService;

	protected chosenCompany: CompanyInitial;

	protected async changeCompany(): Promise<void>
	{
		if (this.chosenCompany == null)
		{
			return;
		}

		try
		{
			await this._identityService.identity.switchCompany(this.chosenCompany.id);

			await this._modalService.closeAll("cancel");
		}
		catch (error: any)
		{
			this._toastService.open(
				"error",
				{
					"message": "Hov, vi kunne ikke skifte virksomhed"
				}
			);
		}
	}

	protected async onCloseClick(): Promise<void>
	{
		await this._modalService.closeAll("cancel");
	}
}
