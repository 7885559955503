import { autoinject } from "aurelia-framework";
import { PipelineStep, NavigationInstruction, Next, Redirect } from "aurelia-router";
import { ModalService } from "shared/framework";
import { SignUpEmployeeFlow } from "./modals/sign-up-employee-flow/sign-up-employee-flow";
import { IdentityService } from "./services/identity";
import { EventAggregator } from "aurelia-event-aggregator";

/**
 * Represents a router pipeline step that determines whether the user is authenticated.
 * If the user is not authenticated this will result in a redirect to the login route.
 */
 @autoinject
export class AuthenticatePipelineStep implements PipelineStep
{
	/**
	* Creates a new instance of the type.
	* @param identityService The `IdentityService` instance.
	*/
	public constructor(identityService: IdentityService, modalService: ModalService, eventAggregator: EventAggregator)
	{
		this._identityService = identityService;
		this._modalService = modalService;
		this._eventAggregator = eventAggregator;
	}

	private readonly _identityService: IdentityService;
	private readonly _modalService: ModalService;
	private readonly _eventAggregator: EventAggregator;

	private timeout: any;

	/**
	* Called by the router when this step should execute.
	* @param navigationInstruction The current navigation instruction.
	* @param next A callback to indicate when pipeline processing should advance to the next step or be aborted.
	* @returns A promise that will be resolved when this step is complete.
	*/
	public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any>
	{
		clearTimeout(this.timeout);

		if (
			navigationInstruction.config.name !== "log-in" &&
			!navigationInstruction.params.childRoute.includes("tilmeld-firma") &&
			!navigationInstruction.params.childRoute.includes("aftaledokumenter/opret-adgangskode"))
		{
			if (!this._identityService.identity.authenticated)
			{
				return next.cancel(new Redirect("log-ind", { replace: true }));
			}
			else
			{
				if (this._identityService.identity.company?.documentsSigned === false)
				{
					if (navigationInstruction.config.name !== "sign-up")
					{
						return next.cancel(new Redirect("tilmeld/aftaledokumenter", { replace: true }));
					}
				}
				else
				{
					if (navigationInstruction.config.name === "sign-up")
					{
						return next.cancel(new Redirect("dashboard/medarbejdere", { replace: true }));
					}
					else
					{
						// Activate timer to show the modal to prompt the user to sign up their first employee
						if (
							this._identityService.identity.company?.employeesInvited === false &&
							!this._identityService.identity.company?.firstEmployeePrompted)
						{
							this.signUpFirstEmployee();
						}
					}
				}
			}
		}

		return next();
	}

	protected async signUpFirstEmployee(): Promise<void>
	{
		this.timeout = setTimeout(
			async () => {
				if (this._modalService.modals.length === 1)
				{
					await this._modalService.modals[0].promise;
					clearTimeout(this.timeout);
					this.signUpFirstEmployee();
				}
				else if (this._modalService.modals.length === 0)
				{
					await this._modalService.open(SignUpEmployeeFlow, { employeeFlow: "add-employee", firstEmployee: true }).promise;

					this._eventAggregator.publish("sign:up:employee:flow");
					this._identityService.identity.company!.firstEmployeePrompted = true;
				}
			},
			2000
		)
	}
}
