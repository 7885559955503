import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { IValidation, ModalService, ToastService } from "shared/framework";
import { IdentityService } from "company-dashboard/services/identity";
import { EmployeeSignUp, EmployeeService } from "company-dashboard/model/dashboard/employee";
import { Operation } from "shared/utilities";
import { DateTime } from "luxon";

@autoinject
export class PersonalInformation
{
	public constructor(
		identityService: IdentityService,
		employeeService: EmployeeService,
		modalService: ModalService,
		toastService: ToastService)
	{
		this.identityService = identityService;
		this._employeeService = employeeService;
		this._modalService = modalService;
		this._toastService = toastService;
	}

	private readonly _employeeService: EmployeeService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	protected readonly identityService: IdentityService;

	protected operation: Operation;

	protected validation: IValidation;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected state: "add" | "edit";

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected employee: EmployeeSignUp;

	@bindable
	protected onNext: () => void;

	@bindable
	protected onCloseClick: () => void;

	protected firstLoad: boolean = true;

	protected async employeeChanged(newValue: EmployeeSignUp): Promise<void>
	{
		if (this.firstLoad)
		{
			if (newValue.id != null)
			{
				await this.getPersonalInformation();
			}
			else
			{
				await this.getSignUpEmployeeInformation();
			}
			this.firstLoad = false;
		}
	}

	@computedFrom("employee.personalInformation.socialSecurityNumber")
	protected get socialSecurityNumberValid(): "too-young" | "not-valid" | "valid"
	{
		if (this.employee?.personalInformation.socialSecurityNumber == null || this.employee.personalInformation.socialSecurityNumber.length !== 10)
		{
			return "not-valid";
		}

		const birthDay: number = Number(this.employee.personalInformation.socialSecurityNumber.substring(0, 2));
		const birthMonth: number = Number(this.employee.personalInformation.socialSecurityNumber.substring(2, 4));
		let birthYear: number = Number(this.employee.personalInformation.socialSecurityNumber.substring(4, 6));
		if (birthYear >= 40)
		{
			birthYear += 1900;
		}
		else
		{
			birthYear += 2000;
		}
		const birthDate = DateTime.local(birthYear, birthMonth, birthDay);

		if (!birthDate.isValid)
		{
			return "not-valid";
		}

		if (birthDate.diffNow("years").years > -18)
		{
			return "too-young";
		}

		return "valid";
	}

	protected async getPersonalInformation(): Promise<void>
	{
		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				// Fetch the data
				const result = await this._employeeService.getPersonalInformation(
					this.employee.id,
					signal
				);

				this.employee = result;

				if (this.employee.fromPayrollSystem)
				{
					this.validation.active = true;
				}
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": `An error occurred.\n${error.message.toString()}`
					}
				)
			}
		})
	}

	protected async getSignUpEmployeeInformation(): Promise<void>
	{
		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				// Fetch the data
				const result = await this._employeeService.getSignUpEmployeeData(signal);

				this.employee = result;
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": `An error occurred.\n${error.message.toString()}`
					}
				)
			}
		})
	}

	protected async onUpdatePersonalInformation(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				this.employee.capitalizeNames();

				await this._employeeService.updatePersonalInformation(this.employee, signal);

				// this.updateEmployeesPage = true;

				await this._modalService.closeAll("cancel");

				this._toastService.open(
					"success",
					{
						heading: `Oplysninger for ${this.employee.personalInformation.firstName} er blevet ændret.`
					}
				)
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				);
			}
		})
	}

	protected async onNextClick(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		this.onNext();
	}
}
