import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { IValidation, ThemeService, ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";

@autoinject
export class AdminSignUpFromBroker
{

	public constructor(
		modal: Modal,
		identityService: IdentityService,
		toastService: ToastService,
		themseService: ThemeService)
	{
		this._modal = modal;
		this._identityService = identityService;
		this._toastService = toastService;
		this.themeService = themseService;
	}

	private readonly _modal: Modal;
	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;
	protected readonly themeService: ThemeService;
	private passwordReset = false;

	/**
	 * The validation for the modal.
	 */
	protected validation: IValidation;

	protected receiveMarketingInformation: boolean = false;

	protected password: string;

	protected confirmPassword: string;

	protected updateOperation: Operation;

	protected model: { token: string, id: string };

	/**
	 * Called by the framework when the modal is activated.
	 * @param model The model to use for the modal.
	 */
	public activate(model: { token: string, id: string }): void
	{
		this.model = model;
	}

	/**
	 * Called by the framework when the modal is deactivated.
	 * @returns True if the password has been reset.
	 */
	public deactivate(): boolean
	{
		return this.passwordReset;
	}

	/**
	 * Check if the password is 8 characters long
	 * contains atleast a number, and letters
	 */
	protected get passwordValid(): boolean
	{
		if (this.password == null)
		{
			return false;
		}

		if (this.password.length < 8)
		{
			return false;
		}

		if (!/\d/.test(this.password))
		{
			return false;
		}

		if (!isNaN(Number(this.password)))
		{
			return false;
		}

		return true;
	}

	protected async onResetPassword(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._identityService.claimCompany(this.password, this.confirmPassword, this.model.token, this.model.id, this.receiveMarketingInformation, signal);
				this.passwordReset = true;

				this._toastService.open(
					"success",
					{
						heading: "Din adgangskode er nu oprettet."
					}
				);

				await this._modal.close();
			}
			catch (error)
			{
				this._toastService.open(
					"error",
					{
						"error": error
					}
				);
			}
		});
	}
}
