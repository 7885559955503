import { textCase } from "shared/utilities";

/**
 * Represents the slug identifying a `PayrollSystem`.
 */
export type PayrollSystemSlug = keyof typeof PayrollSystem.values;

/**
 * Represents the salary system.
 */
export class PayrollSystem
{
	/**
	 * Create a new instance of this using JSON data
	 */
	 public static payrollSystemFromJSON(value: number): PayrollSystem
	 {
		 if (value === 0)
		 {
			 return new PayrollSystem("unknown");
		 }
		 else if (value === 2)
		 {
			 return new PayrollSystem("salary");
		 }
		 else if (value === 3)
		 {
			 return new PayrollSystem("zenegy");
		 }
		 else if (value === 4)
		 {
			 return new PayrollSystem("danloen");
		 }

		 return new PayrollSystem("none");
	 }

  /**
   * Creates a new instance of the type.
   * @param slug The slug identifying the salary system.
   */
  public constructor(slug: PayrollSystemSlug)
  {
    this.slug = textCase(slug, "pascal", "kebab") as any;
    Object.assign(this, PayrollSystem.values[this.slug]);
  }

  public slug: PayrollSystemSlug;
  public name: string;
	public logo: { dark: string, light: string }
  public value: number;

  /**
   * Gets the data representing this instance.
   */
  public toJSON(): any
  {
    return this.value;
  }

  /**
   * The supported values.
   */
  public static readonly values =
  {
    "unknown":
    {
      name: "unknown",
      value: 0
    },
    "none":
    {
      name: "none",
      value: 1
    },
    "salary":
    {
      name: "Salary",
			logo: { dark: "salary-logo--dark.svg", light: "salary-logo--white.svg" },
      value: 2
    },
    "zenegy":
    {
      name: "Zenegy",
			logo: { dark: "zenegy-logo.svg", light: "zenegy-logo.svg" },
      value: 3
    },
    "danloen":
    {
      name: "Danløn",
			logo: { dark: "danloen--dark.svg", light: "danloen-logo--white.svg" },
      value: 4
    },
  };
}
