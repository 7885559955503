import { IdentityService } from "company-dashboard/services/identity";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ModalService, ThemeService } from "shared/framework";
import { Router } from "aurelia-router";
import { SignUpEmployeeFlow } from "company-dashboard/modals/sign-up-employee-flow/sign-up-employee-flow";
import { ChangeCompany } from "company-dashboard/modals/change-company/change-company";

/**
 * Represents a menu at the top of the page.
 */
@autoinject
export class AppTopMenu
{
	public constructor(
		identityService: IdentityService,
		themeService: ThemeService,
		modalService: ModalService,
		router: Router)
	{
		this._identityService = identityService;
		this._router = router;
		this._themeService = themeService;
		this._modalService = modalService;
	}

	protected readonly _identityService: IdentityService;
	protected readonly _router: Router;
	protected readonly _themeService: ThemeService;
	protected readonly _modalService: ModalService;

	/**
	 * Whether or not the menu should be open (works on small devices)
	 */
	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected menuOpen: boolean;

	protected profileMenu: any;

	protected async onSignUpEmployeeClick(): Promise<void>
	{
		this.profileMenu.open = false;
		await this._modalService.open(SignUpEmployeeFlow, { employeeFlow: "add-employee" }).promise;

		this._router.navigate("medarbejdere");
	}

	protected async onChangeCompanyClick(): Promise<void>
	{
		this.profileMenu.open = false;
		await this._modalService.open(ChangeCompany).promise;

		location.reload();
	}

	protected logOut(): void
	{
		this._identityService.identity.logout();
		this._router.navigate("log-ind");
	}
}
