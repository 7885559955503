import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Operation } from "shared/utilities";
import { ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";

/**
 * Represents the module.
 */
@autoinject
export class YourDashboardModule extends BaseModule
{
	constructor(
		identityService: IdentityService,
		toastService: ToastService,
		router: Router)
	{
		super()

		this._identityService = identityService;
		this._toastService = toastService;
		this._router = router;
		this._contructed = true;
	}

	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;
	private readonly _router: Router;
	private readonly _contructed: boolean;

	private timeout: any;

	doActivate(): void
	{
		this.update();
	}

	deactivate()
	{
		clearTimeout(this.timeout);
	}

/**
	 * Updates the page by fetching the latest data.
	 */
 protected update(): void
 {
	// Returns if the object is not contructed.
	// This is needed because the `observable` decorator called the change handler when the
	// initial property value is set, which happens before the constructor is called
	if (!this._contructed)
	{
		return;
	}

	if (this.updateOperation != null)
	{
		this.updateOperation.abort();
	}

	// Create and execute the operation
	this.updateOperation = new Operation(async signal =>
	{
		try
		{
			// Fetch the data
			await this._identityService.initial(signal);

			this.timeout = setTimeout(
				() => this._router.navigate("medarbejdere"),
				2500
			)
		}
		catch (error: any)
		{
			this._toastService.open(
				"error",
				{
					"message": error.message
				}
			)
		}
	});
 }
}
