import { autoinject, PLATFORM } from "aurelia-framework";
import { RouterConfiguration, Router } from "aurelia-router";

/**
 * Represents the module.
 */
@autoinject
export class DashboardModule
{
	public configureRouter(config: RouterConfiguration, router: Router): void
	{
		let routeConfig = [
			{
				name: "default",
				route: "",
				redirect: "medarbejdere"
			},
			{
				name: "employees",
				route: "medarbejdere",
				moduleId: PLATFORM.moduleName("./modules/employees/employees"),
				title: "Medarbejdere",
				nav: true,
				icon: "people"
			},
			{
				name: "company",
				route: "virksomheden",
				moduleId: PLATFORM.moduleName("./modules/company/company"),
				title: "Virksomheden",
				nav: true,
				icon: "building"
			},
			{
				name: "payroll-system",
				route: [ "loensystem", "loensystem/:payrollSystem" ],
				moduleId: PLATFORM.moduleName("./modules/payroll-system/payroll-system"),
				title: "Lønsystem",
				nav: true,
				icon: "document"
			}
		]

		config.map(routeConfig);
	}
}
