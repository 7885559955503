import { autoinject, bindable } from "aurelia-framework";
import { ConnectToSalary } from "company-dashboard/modals/connect-to-payroll-system/connect-to-salary/connect-to-salary";
import { ConnectToZenegy } from "company-dashboard/modals/connect-to-payroll-system/connect-to-zenegy/connect-to-zenegy";
import { PayrollSystemConnection, PayrollSystemResponse, PayrollSystemService } from "company-dashboard/model/dashboard/company";
import { PayrollSystem } from "company-dashboard/model/dashboard/shared";
import { ModalService, ThemeService, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";
import { DisconnectFromPayrollSystem } from "./modals/disconnect-from-payroll-system/disconnect-from-payroll-system";

interface PayrollSystemParams {
    payrollSystem: "zenegy" | undefined;
    code: string | undefined;
}

/**
 * Represents the module.
 */
@autoinject
export class PayrollSystemModule extends BaseModule {
    /**
     * Creates a new instance of the type.
     */
    public constructor(themeService: ThemeService, modalService: ModalService, toastService: ToastService, payrollSystemService: PayrollSystemService) {
        super();

        this.themeService = themeService;
        this._modalService = modalService;
        this._toastService = toastService;
        this._payrollSystemService = payrollSystemService;
    }

    protected readonly themeService: ThemeService;
    private readonly _modalService: ModalService;
    private readonly _toastService: ToastService;
    private readonly _payrollSystemService: PayrollSystemService;

    protected failed: boolean = false;

    @bindable
    protected payrollSystemInformation: PayrollSystemResponse;

    /**
     * The most recent update operation
     */
    protected updateOperation: Operation;

    /**
     * Called by the framework when the module is activated.
     * @returns A promise that will be resolved, when the module is activated.
     */
    public async doActivate(params: PayrollSystemParams): Promise<void> {
        this.resetData();

        if (params.payrollSystem === "zenegy" && params.code != null) {
            try {
                await this._payrollSystemService.addPayrollSystemApiToken(
                    params.code,
                    new PayrollSystem("zenegy")
                )
            }
            catch (error: any) {
                this._toastService.open(
                    "error",
                    {
                        "message": "Nøglen kunne ikke tilføjes."
                    }
                );
            }
        }

        await this.update();
    }

    protected resetData(): void {
        this.payrollSystemInformation = new PayrollSystemResponse({ payrollSystem: 1, connections: [] });
    }

    protected async openConnectToSalaryModal(): Promise<void> {
        await this._modalService.open(ConnectToSalary).promise;

        this.update();
    }

    protected async openConnectToZenegyModal(): Promise<void> {
        await this._modalService.open(ConnectToZenegy).promise;
    }

    protected async openDisconnectFromPayrollSystemModal(connection: PayrollSystemConnection): Promise<void> {
        const disconnectedFromPayrollSystem = await this._modalService.open(DisconnectFromPayrollSystem, { connection: connection, payrollSystem: this.payrollSystemInformation.payrollSystem }).promise;

        if (disconnectedFromPayrollSystem) {
            this.update();
        }
    }

    /**
     * Updates the page by fetching the latest data.
     */
    protected async update(): Promise<void> {
        if (this.updateOperation != null) {
            this.updateOperation.abort();
        }

        // Create and execute the operation
        this.updateOperation = new Operation(async signal => {
            this.failed = false;

            try {
                // Fetch the data
                const result = await this._payrollSystemService.getPayrollSystemInformation(signal);

                this.payrollSystemInformation = result;
            }
            catch (error: any) {
                console.log(error, error.message);
            }
        })
    }
}
