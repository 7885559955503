import { textCase } from "shared/utilities";

/**
 * Represents the slug identifying a `DocumentSigningGroup`.
 * A document signing group is essentially a grouping of signed or unsigned documents.
 */
export type DocumentSigningGroupSlug = keyof typeof DocumentSigningGroup.values;

/**
 * Represents the document singing group.
 */
export class DocumentSigningGroup
{
  /**
   * Creates a new instance of the type.
   * @param slug The slug identifying the document signing group.
   */
  public constructor(slug: DocumentSigningGroupSlug)
  {
    this.slug = textCase(slug, "pascal", "kebab") as any;
    Object.assign(this, DocumentSigningGroup.values[this.slug]);
  }

  public slug: DocumentSigningGroupSlug;
  public name: string;
  public value: number;

  /**
   * Gets the data representing this instance.
   */
  public toJSON(): any
  {
    return this.value;
  }

  /**
   * The supported values.
   */
  public static readonly values =
  {
    "velliv":
    {
      name: "Velliv",
      value: 1
    },
    "grandhood":
    {
      name: "Grandhood",
      value: 2
    },
    "non-signing-group":
    {
      name: "Non-signing group",
      value: 0
    }
  };
}
