import { EmployeeSignUp } from "company-dashboard/model/dashboard/employee";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ThemeService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";

@autoinject
export class SignUpEmployeeFlow
{
	public constructor(
		modal: Modal,
		themeService: ThemeService)
	{
		this._modal = modal;
		this._themeService = themeService;
	}

	private readonly _modal: Modal;
	protected readonly _themeService: ThemeService;

	protected getEmployeeOperation: Operation;

	protected pensionStructureOperation: Operation;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected updateEmployeesPage: boolean = false;

	@bindable
	protected model: {
		employeeFlow: "add-employee" | "place-of-residence" | "personal-information" | "salary-and-pension" | undefined,
		firstEmployee?: boolean
		employeeId?: number,
		employeeFirstName?: string
	};

	@bindable({ defaultBindingMode: bindingMode.fromView })
	protected state: "add-employee" | "place-of-residence" | "personal-information" | "salary-and-pension" | "first-employee";

	protected get imageUrl(): string
	{
		if (this.state === "add-employee")
		{
			if (!this.updateEmployeesPage)
			{
				return `/resources/themes/${this._themeService.theme.slug}/images/looking-at-grandhood-app.jpg`;
			}
			else if (this.updateEmployeesPage)
			{
				return `/resources/themes/${this._themeService.theme.slug}/images/employee-signed-up.jpg`;
			}
		}
		else if (this.state === "first-employee" || this.state === "personal-information" || "place-of-residence" || this.state === "salary-and-pension")
		{
			return `/resources/themes/${this._themeService.theme.slug}/images/grandhood-app-core-experience.jpg`;
		}

		return "";
	}

	/**
	 * Called by the framework when the modal is activated.
	 * @param model The model to use for the modal.
	 */
	public async activate(
		model: {
			employeeFlow: "add-employee" | "place-of-residence" | "personal-information" | "salary-and-pension" | undefined,
			firstEmployee?: boolean,
			employeeId?: number,
			employeeFirstName?: string
		}): Promise<void>
	{
		this.model = model;

		// Edit employee
		if (model.employeeId != null)
		{
			if (
				model.employeeFlow === "personal-information" ||
				model.employeeFlow === "place-of-residence" ||
				model.employeeFlow === "salary-and-pension")
			{
				this.state = model.employeeFlow;
			}
		}
		else
		{
			if (model.firstEmployee)
			{
				this.state = "first-employee";
			}
			else
			{
				this.state = "add-employee";
			}
		}
	}

	public deactivate(): EmployeeSignUp | undefined
	{
		return undefined;
	}

	protected async onNextClick(): Promise<void>
	{
		if (this.state === "first-employee")
		{
			this.state = "add-employee";
		}
	}

	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}
}
