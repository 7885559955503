import { EmployeeService } from "company-dashboard/model/dashboard/employee";
import { autoinject } from "aurelia-framework";
import { IValidation, ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";
import { DateTime } from "luxon";

@autoinject
export class DeactivateEmployee
{
	private readonly _modal: Modal;
	private readonly _employeeService: EmployeeService;
	private readonly _toastService: ToastService;

	public constructor(
		modal: Modal,
		employeeService: EmployeeService,
		toastService: ToastService)
	{
		this._modal = modal;
		this._employeeService = employeeService;
		this._toastService = toastService;
	}

	protected validation: IValidation;

	protected model: { employeeId: number, employeeFirstName: string };

	protected deactivateOperation: Operation;

	protected employeeDeactivated: boolean = false;

	protected endDate: DateTime = DateTime.local().endOf("month");

	public activate(model: { employeeId: number, employeeFirstName: string }): void
	{
		this.model = model;
	}

	public deactivate(): boolean
	{
		return this.employeeDeactivated;
	}

	protected get minimumValidEndDate(): DateTime
	{
		const today = DateTime.local();

		return today.set({ day: 1 }).startOf("day");
	}

	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}

	protected async onDeactivateClick(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		this.deactivateOperation = new Operation(async signal =>
		{
			try
			{
				await this._employeeService.deactivateEmployee(this.model.employeeId, this.endDate);

				this.employeeDeactivated = true;

				this._toastService.open(
					"neutral",
					{
						heading: this.model.employeeFirstName,
						body: `bliver deaktiveret fra pensionsordningen den ${this.endDate.toFormat("dd. LLLL.", { locale: "da" })}`,
						icon: "person--negative"
					}
				)

				this._modal.close();
			} catch (error: any)
			{
				if ([400, 401, 403].includes(error.response.status))
				{
					this._toastService.open(
						"error",
						{
							"message": "Medarbejderen kunne ikke blive deaktiveret."
						}
					)
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
						}
					)
				}

				console.log("An error occurred.\n", error, signal);
			}
		})
	}
}
