import { AdministratorService } from "company-dashboard/model/dashboard/company/services/administrator-service";
import { autoinject } from "aurelia-framework";
import { IValidation, ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";
import { bindable } from "aurelia-typed-observable-plugin";

@autoinject
export class AddAdministrator
{
	private readonly _modal: Modal;
	private readonly _administratorService: AdministratorService;
	private readonly _toastService: ToastService;

	public constructor(modal: Modal, administratorService: AdministratorService, toastService: ToastService)
	{
		this._modal = modal;
		this._administratorService = administratorService;
		this._toastService = toastService;
	}

	protected validation: IValidation;

	@bindable
	protected administratorAdded = false;

	protected firstName: string;
	protected lastName: string;
	protected email: string;

	protected updateOperation: Operation;

	public deactivate(): boolean
	{
			return this.administratorAdded;
	}

	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}

	protected async onAddAdministrator(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._administratorService.addAdministrator(this.firstName, this.lastName, this.email, signal);

				this.administratorAdded = true;

				this._toastService.open(
					"success",
					{
						heading: this.firstName,
						body: "blev tilføjet som administrator."
					}
				)

				await this._modal.close();
			}
			catch (error: any)
			{
				if ([400, 401, 403].includes(error.response.status))
				{
					this._toastService.open(
						"error",
						{
							"message": "Administratoren kunne ikke blive tilføjet."
						}
					)
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
						}
					)
				}

				console.log("An error occurred.\n", error, signal);
			}
		})
	}
}
