import clone from "clone";
import { PayrollSystem } from "../../shared";
import { EmployeeSimple } from "./employee-simple";

export class EmployeesPageResponse
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
		this.employees = data.employees.map((data: any) => new EmployeeSimple(data));
		this.payrollSystem = PayrollSystem.payrollSystemFromJSON(data.payrollSystem);
  }

	public readonly employees: EmployeeSimple[];

  public readonly payrollSystem: PayrollSystem;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
