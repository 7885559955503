import { autoinject, bindable } from "aurelia-framework";
import { ModalService } from "shared/framework";

@autoinject
export class FirstEmployee
{
	public constructor(modalService: ModalService)
	{
		this._modalService = modalService;
	}
	protected readonly _modalService: ModalService;

  @bindable
  public onNextClick: () => void;
}
