import clone from "clone";
import { PayrollSystem } from "../../shared";

export class PayrollSystemResponse {
    /**
     * Creates a new instance of the type.
     * @param data The response data from which the instance should be created.
     */
    public constructor(data: any) {
        this.payrollSystem = PayrollSystem.payrollSystemFromJSON(data.payrollSystem);
        this.connections = data.connections.map((connection: any) => new PayrollSystemConnection(connection));
    }

    public readonly payrollSystem: PayrollSystem;

    public readonly connections: PayrollSystemConnection[];

    /**
     * Gets a clone of this instance, suitable for editing.
     */
    public clone(): any {
        return clone(this);
    }
}

export class PayrollSystemConnection {
    /**
     * Creates a new instance of the type.
     * @param data The response data from which the instance should be created.
     */
    public constructor(data: any) {
        this.id = data.id;
        this.companyName = data.companyName;
        this.connectionAvailable = data.connectionAvailable;
    }

    public readonly id: number;

    public readonly companyName: string;

    public readonly connectionAvailable: boolean;

    /**
     * Gets a clone of this instance, suitable for editing.
     */
    public clone(): any {
        return clone(this);
    }
}
