import clone from "clone";
import { DateTime } from "luxon";
import { EmployeeStatus } from "./employee-status";
import { EmployeeAddress } from "./employee-address";
import { EmployeeCompensationInformation } from "..";
import { EmployeePersonalInformation } from "./employee-personal-information";

/**
 * The detailed instance of an employee.
 */
export class EmployeeSignUp
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.id = data.id == null ? undefined : data.id;

		this.fromPayrollSystem = data.fromPayrollSystem;
		this.payrollSystemUrl = data.payrollSystemUrl;

		this.personalInformation = new EmployeePersonalInformation(data.personalInformation ?? {});
		this.address = new EmployeeAddress(data.addressInformation ?? {});
		this.compensationInformation = new EmployeeCompensationInformation(
			{
				...data.compensationInformation,
				employeeGroups: data.employeeGroups,
				subsidiaries: data.subsidiaries
			}
		);

		this.status = EmployeeStatus.fromJSON(data.status);
		this.ownersTaxedPrivately = data.ownersPersonallyTaxed;
		this.agreementEndDate = data.agreementEndDate == null ? undefined : DateTime.fromISO(data.agreementEndDate);
		this.companyAgreementStartDate = DateTime.fromISO(data.companyAgreementStartDate);
	}

	public id: number;

	public fromPayrollSystem: boolean;

	public payrollSystemUrl?: string;

	public personalInformation: EmployeePersonalInformation;

	public address: EmployeeAddress;

	public compensationInformation: EmployeeCompensationInformation;

	public readonly status: EmployeeStatus | undefined;

	public readonly ownersTaxedPrivately: boolean;

  public readonly agreementEndDate?: DateTime;

	public readonly companyAgreementStartDate: DateTime;

	/**
	 * Concatenates the first and last name of the Employee
	 */
	public get fullName(): string
	{
		return `${this.personalInformation.firstName} ${this.personalInformation.lastName}`;
	}

	/**
	 * Capitalizes the first and last name of the Employee
	 */
	public capitalizeNames(): void
	{
		this.personalInformation.firstName = this.personalInformation.firstName.split(" ").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
		this.personalInformation.lastName = this.personalInformation.lastName.split(" ").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
	}

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}

	/**
	 * Gets the data representing this instance.
	 */
	public toJSON(mandatory: boolean): any
	{
		return {
			id: this.id,
			personalInformation: this.personalInformation.toJSON(),
			addressInformation: mandatory ? this.address : undefined,
			compensationInformation: this.compensationInformation.toJSON()
		};
	}
}
