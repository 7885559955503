import { Cookies } from "shared/infrastructure";
import { AdministratorSimple, CompanyInitial } from "..";
import { computedFrom, autoinject } from "aurelia-framework";
import njwt from "njwt";
import { DateTime } from "luxon";

const enum Path
{
	ActiveCompanyId = "active-company-id",
	AccessToken = "access-token",
	RefreshToken = "refresh-token",
	Locale = "locale"
}

/**
 * Represents the identity of an authenticated user.
 */
 @autoinject
export class Identity
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor()
	{
		this.companies = CompanyInitial[0];

		const accessToken = this._cookies.get(Path.AccessToken);
		const refreshToken = this._cookies.get(Path.RefreshToken);

		if (accessToken != null && refreshToken != null)
		{
			this.tokens = { access: accessToken, refresh: refreshToken };
		}
	}

	private readonly _cookies: Cookies = new Cookies();

	/**
	 * The tokens to use when accessing the API.
	 */
	public tokens: { refresh: string; access: string } | undefined;

	/**
	 * The information of the user logged in.
	 */
	public administrator: AdministratorSimple;

	/**
	 * The active company currently administered by the user.
	 */
	 public company: CompanyInitial | undefined;

	/**
	 * The companies the user is allowed to administer.
	 */
	public companies: CompanyInitial[];

	/**
	 * Sets the access and refresh tokens for the user
	 * and checks the user as authenticated
	 */
	public setTokens(accessToken: string, refreshToken: string): void
	{
		this._cookies.set(Path.RefreshToken, refreshToken, { path: "/" });
		this._cookies.set(Path.AccessToken, accessToken, { path: "/" });
		this.tokens = { access: accessToken, refresh: refreshToken };
	}

	/**
	 * Gets and fill out the data from the initial call
	 */
	public setInitialInformations(data: any): void
	{
		this.administrator = new AdministratorSimple(data.administrator);
		this.companies = data.companies.map((companyData: any) => new CompanyInitial(companyData));

		let activeCompanyId = this.activeCompanyId;
		if(activeCompanyId == null || this.companies.find(company => company.id === activeCompanyId) == null)
		{
			activeCompanyId = this.companies[this.companies.length - 1].id;

			// Setting the initial company id as there either no nothing currently
			// or the currently set does not match any companies the administrator
			// has access to.
			this._cookies.set(Path.ActiveCompanyId, activeCompanyId.toString(), { path: "/" });
		}

		this.company = this.companies.find(company => company.id === activeCompanyId);
	}

	private get activeCompanyId(): number | undefined
	{
		const activeCompanyIdString = this._cookies.get(Path.ActiveCompanyId);
		if(activeCompanyIdString == null)
		{
			return undefined;
		}

		const activeCompanyId = parseInt(activeCompanyIdString);
		if(isNaN(activeCompanyId) || !isFinite(activeCompanyId))
		{
			return undefined;
		}

		return activeCompanyId;
	}

	/**
	 * Switches the active company to the provided
	 */
	public switchCompany(companyId: number): void
	{
		this._cookies.set(Path.ActiveCompanyId, companyId.toString(), { path: "/" });
	}

	/**
	 * Whether or not the user is authenticated.
	 */
	 @computedFrom("tokens")
	public get authenticated(): boolean
	{
		if (this.tokens == null)
		{
			return false;
		}

		const expires = this.accessTokenExpirationDateTime;

		if (expires < DateTime.local())
		{
			return false;
		}

		return true;
	}

	public get accessTokenExpirationDateTime(): DateTime
	{
		let expirationDateInSeconds: number;

		try
		{
			const jwt = njwt.verify(this.tokens?.access);
			expirationDateInSeconds = jwt.parsedBody.exp;
		}
		catch(error: any)
		{
			if (error.parsedBody == null)
			{
				throw new Error(`Could not parse the JWT token. ${error.message}`);
			}

			expirationDateInSeconds = error.parsedBody.exp;
		}

		return DateTime.fromSeconds(expirationDateInSeconds);
	}

	/**
	 * Resets the identity of the user
	 */
	private reset(): void
	{
		this.tokens = undefined;
		this.company = undefined;

		this._cookies.set(Path.ActiveCompanyId, undefined, { path: "/" });
		this._cookies.set(Path.AccessToken, undefined, { path: "/" });
		this._cookies.set(Path.RefreshToken, undefined, { path: "/" });
	}

	/**
	 * The logout triggers a reset of the identity of the user
	 */
	public logout(): void
	{
		this.reset();
	}
}
