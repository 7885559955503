import clone from "clone";

export class AgreementDocument
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.s3Key = data.s3Key;
  }

  public readonly id: number;

  public readonly title: string;

  public readonly description: string;

  public readonly s3Key: string;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
