import { AdministratorSimple, IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { AgreementDocument, CompanyDetailed } from "..";

export interface CompanyPageInformation
{
  companyInformation: CompanyDetailed,
  agreementDocuments: AgreementDocument[],
  administrators: AdministratorSimple[]
}

/**
 * Represents a service that manages the company page.
 */
@autoinject
export class CompanyService
{
  /**
   * Creates a new instance of the type.
   * @param apiClient The `ApiClient` instance.
   * @param identityService The `IdentityService` instance.
   */
  public constructor(apiClient: ApiClient, identityService: IdentityService)
  {
    this._apiClient = apiClient;
    this._identityService = identityService;
  }

  private readonly _apiClient: ApiClient;
  private readonly _identityService: IdentityService;

  /**
   * Get the company information displayed in the .
   * @param signal The abort signal to use, or undefined to use no abort signal.
   * @returns A promise that will be resolved with a list of administrators.
   */
  public async getCompanyInformations(signal?: AbortSignal): Promise<CompanyPageInformation>
  {
    const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/company`,
    {
      signal
    });

    return {
      companyInformation: new CompanyDetailed(result.data.companyInformation),
      administrators: result.data.administrators.map((administrator: any) => new AdministratorSimple(administrator)),
      agreementDocuments: result.data.documents.map((document: any) => new AgreementDocument(document))
    };
  }
}
