import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";

@autoinject
export class AgreementDocumentService
{
	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	public constructor(
		apiClient: ApiClient,
		identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	public async getAgreementDocument(s3Key: string, signal?: AbortSignal): Promise<Blob>
	{
		let data = await this._apiClient.get(
			`/${this._identityService.identity.company?.id}/document/${s3Key}`,
			{
				signal
			}
		);

		return data.response.blob();
	}
}
