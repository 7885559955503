import clone from "clone";
import { DocumentSigningGroup } from "..";

export class UnsignedDocument
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.documentSigningGroup = this.documentSigningGroupFromJSON(data.signingGroupNumber);
    this.currentSigningGroupNumber = data.currentSigningGroupNumber;
    this.totalSigningGroups = data.totalSigningGroups;
    this.agreementCompanyName = data.agreementCompanyName;
    this.signingGroupText = data.signingGroupText;
  }

  public readonly documentSigningGroup: DocumentSigningGroup;

  public readonly currentSigningGroupNumber: number;

  public readonly totalSigningGroups: number;

  public readonly agreementCompanyName: string;

  public readonly signingGroupText: string;

  /**
   * Create a new instance of this using JSON data
   */
  public documentSigningGroupFromJSON(value: number): DocumentSigningGroup
  {
    if (value === 1)
    {
      return new DocumentSigningGroup("velliv");
    }
    else if (value === 2)
    {
      return new DocumentSigningGroup("grandhood");
    }

    return new DocumentSigningGroup("non-signing-group");
  }

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
