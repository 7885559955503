import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { Administrator, Company, CompanyTypeahead } from "..";

@autoinject
export class SignUpCompanyService
{
	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	public constructor(
		apiClient: ApiClient,
		identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	public async searchForCompany(
		query: string,
		signal?: AbortSignal): Promise<CompanyTypeahead[]>
	{
		const response = await this._apiClient.get(
			"/company/typeahead",
			{
				query: { companyName: query },
				signal
			}
		);

		return response.data.map(companyData => new CompanyTypeahead(companyData));
	}

	public async signUpCompany(
		company: Company,
		administrator: Administrator,
		signal?: AbortSignal): Promise<void>
	{
		const response = await this._apiClient.post(
			"/company/register",
			{
				body: { administrator: administrator, ...company },
				signal
			}
		);

		try
		{
			this._identityService.identity.setTokens(response.data.accessToken, response.data.refreshToken);
		}
		finally
		{
			this._identityService.configureInfrastructure();
		}
	}
}
