import { autoinject } from "aurelia-framework";
import { PayrollSystemConnection, PayrollSystemService } from "company-dashboard/model/dashboard/company";
import { PayrollSystem } from "company-dashboard/model/dashboard/shared";
import { ModalService, ThemeService, ToastService } from "shared/framework";
import { Operation } from "shared/utilities";

@autoinject
export class DisconnectFromPayrollSystem
{
  public constructor(themeService: ThemeService, modalService: ModalService, toastService: ToastService, payrollSystemService: PayrollSystemService)
  {
    this.themeService = themeService;
		this._modalService = modalService;
		this._toastService = toastService;
		this._payrollSystemService = payrollSystemService;
  }

  protected readonly themeService: ThemeService;
	private readonly _modalService: ModalService;
	private readonly _toastService: ToastService;
	private readonly _payrollSystemService: PayrollSystemService;

	protected disconnectedFromPayrollSystem: boolean = false;

	protected connection: PayrollSystemConnection;

	protected payrollSystem: PayrollSystem;

	protected operation: Operation;

	/**
	 * Called by the framework when the modal is activated.
	 */
	public async activate(model: { connection: PayrollSystemConnection, payrollSystem: PayrollSystem }): Promise<void>
	{
		this.connection = model.connection;
		this.payrollSystem = model.payrollSystem;
	}

	public deactivate(): boolean
	{
		return this.disconnectedFromPayrollSystem;
	}

	protected async disconnectFromPayrollSystem(): Promise<void>
	{
		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				await this._payrollSystemService.removePayrollSystemApiToken(this.connection, signal);

				this.disconnectedFromPayrollSystem = true;

				this.onCloseClick();
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				);
			}
		})
	}

	protected async onCloseClick(): Promise<void>
	{
		if (this.disconnectedFromPayrollSystem)
		{
			this._toastService.open(
				"success",
				{
					body: "Lønsystem integration er blevet fjernet fra dit Grandhood Dashboard. "
				}
			)
		}
		await this._modalService.closeAll("cancel");
	}
}
