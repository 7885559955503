import { Router } from "aurelia-router";
import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";

@autoinject
export class AdministratorAlreadyExists
{
	public constructor(
    modal: Modal,
    identityService: IdentityService,
    toastService: ToastService,
    router: Router)
	{
		this._modal = modal;
		this._identityService = identityService;
		this._toastService = toastService;
		this._router = router;
	}

	private readonly _modal: Modal;
	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;
  private readonly _router: Router;

	protected password: string;

	protected updateOperation: Operation;

	protected model: { email: string };

	/**
	 * Called by the framework when the modal is activated.
	 * @param model The model to use for the modal.
	 */
	public activate(model: { email: string }): void
	{
		this.model = model;
	}

	/**
	 * Called by the framework when the modal is deactivated.
	 */
	public deactivate(): void
	{

	}

	protected logIn(): void
	{
		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		if (
			this.password == null ||
			this.password === "")
		{
			this._toastService.open(
				"error",
				{
					"message": "Angiv en adgangskode."
				}
			);

			return;
		}

		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._identityService.logIn(this.model.email, this.password, signal);

				await this._identityService.initial();

        await this._modal.close();

        this._router.navigate("medarbejdere");
			}
			catch (error: any)
			{
				if ([401, 403].includes(error.response.status))
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi kunne ikke genkende adgangskoden. Har du glemt din adgangskode?"
						}
					)
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
						}
					)
				}
				console.log("An error occurred.\n", error, signal);
			}
		});
	}

	/**
	 * Redirects the customer to the forgot password modal
	 */
   public async onForgotPasswordClick(): Promise<void>
   {
     this._router.navigate("log-ind/glemt-adgangskode");
   }
}
