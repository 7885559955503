import { textCase } from "shared/utilities";

/**
 * Represents the slug identifying a `EmployeeStatus`.
 */
export type EmployeeStatusSlug = keyof typeof EmployeeStatus.values;

/**
 * Represents the status of an employee.
 */
export class EmployeeStatus
{
  /**
   * Create a new instance of this using JSON data
   */
   public static fromJSON(value: number): EmployeeStatus
   {
     if (value === 1)
     {
       return new EmployeeStatus("signed-up");
     }
     else if (value === 2)
     {
       return new EmployeeStatus("in-onboarding");
     }
     else if (value === 3)
     {
       return new EmployeeStatus("onboarded");
     }
     else if (value === 4)
     {
       return new EmployeeStatus("deactivated");
     }
     else if (value === 5)
     {
       return new EmployeeStatus("not-supported");
     }
     else if (value === 100)
     {
       return new EmployeeStatus("not-invited");
     }

     // Default to not supported for now.
     return new EmployeeStatus("not-supported");
   }

  /**
   * Creates a new instance of the type.
   * @param slug The slug identifying the status of the employee.
   */
  public constructor(slug: EmployeeStatusSlug)
  {
    this.slug = textCase(slug, "pascal", "kebab") as any;
    Object.assign(this, EmployeeStatus.values[this.slug]);
  }

  public slug: EmployeeStatusSlug;
  public name: string;
  public value: number;

  /**
   * Gets the data representing this instance.
   */
  public toJSON(): any
  {
    return this.value;
  }

  /**
   * The supported values.
   */
  public static readonly values =
  {
    "signed-up":
    {
      name: "Tilmeldt",
      value: 1
    },
    "in-onboarding":
    {
      name: "Igang",
      value: 2
    },
    "onboarded":
    {
      name: "Aktiv",
      value: 3
    },
    "deactivated":
    {
      name: "Deaktiveret",
      value: 4
    },
    "not-supported":
    {
      name: "Ikke supporteret",
      value: 5
    },
    "not-invited":
    {
      name: "Ikke inviteret",
      value: 100
    },
  };
}
