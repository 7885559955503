import { PLATFORM, autoinject, bindable, bindingMode } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import { ModalService, ToastService } from "shared/framework";
import { setPrerenderStatusCode } from "shared/infrastructure";
import { AuthenticatePipelineStep } from "./authenticate-pipeline-step";

/**
 * Represents the app module.
 */
@autoinject
export class AppModule
{
	/**
	 * Creates a new instance of the type.
	 * @param toastService The `ToastService` instance.
	 * @param modalService The `ModalService` instance.
	 * @param router The `Router` instance.
	 */
	public constructor(toastService: ToastService, modalService: ModalService, router: Router)
	{
		this._modalService = modalService;
		this._toastService = toastService;
		this._router = router;

		this.configureToasts();
		this.configureModals();
	}

	/**
	 * The `ToastService` instance.
	 */
	protected readonly _toastService: ToastService;

	/**
	 * The `ModalService` instance.
	 */
	protected readonly _modalService: ModalService;

	/**
	 * The `Router` instance.
	 */
	protected readonly _router: Router;

	/**
	 * Whether or not the menu is open
	 */
	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected menuOpen: boolean = false;

	/**
	 * Called to configure the modals for the app.
	 */
	public configureModals(): void
	{
		const modalConfigs =
		[
			{
				name: "sign-up-employee-flow",
				moduleId: PLATFORM.moduleName("company-dashboard/modals/sign-up-employee-flow/sign-up-employee-flow")
			}
		];

		// Configure the modals.
		modalConfigs.forEach(config => this._modalService.register(config.name, config.moduleId));
	}

	/**
	 * Called to configure the toasts for the app.
	 */
	public configureToasts(): void
	{
		const toastConfigs =
		[
			{
				name: "error",
				moduleId: PLATFORM.moduleName("company-dashboard/toasts/error/error")
			},
			{
				name: "success",
				moduleId: PLATFORM.moduleName("company-dashboard/toasts/success/success")
			},
			{
				name: "neutral",
				moduleId: PLATFORM.moduleName("company-dashboard/toasts/neutral/neutral")
			}
		];

		// Configure the toasts.
		toastConfigs.forEach(config => this._toastService.register(config.name, config.moduleId));
	}

	/**
	 * Called to configure the router for the module.
	 * @param config The router configuration associated with the module.
	 * @param router The router associated with the module.
	 */
	public configureRouter(config: RouterConfiguration, router: Router): void
	{
		const routeConfigs =
		[
			{
				name: "dashboard",
				route: "dashboard",
				moduleId: PLATFORM.moduleName("./modules/dashboard/dashboard"),
				title: "Dashboard",
			},
			{
				name: "sign-up",
				route: "tilmeld",
				moduleId: PLATFORM.moduleName("./modules/sign-up/sign-up"),
				title: "Tilmeld",
			},
			{
				name: "log-in",
				route: [ "log-ind", "log-ind/:modal" ],
				moduleId: PLATFORM.moduleName("./modules/log-in/log-in"),
				title: "Log ind",
			},

			...
			ENVIRONMENT.name === "development" || ENVIRONMENT.name === "test" ?
			[
				{
					name: "design",
					route: "design",
					moduleId: PLATFORM.moduleName("./modules/_design/design"),
					title: "Design"
				}
			] : []
		];

		// Configure the routes.
		config.map(routeConfigs);

		// Map unknown routes.
		config.mapUnknownRoutes(() =>
		{
			// Set the status code that should be returned to crawlers.
			setPrerenderStatusCode(404);

			// Present the `unknown` page.
			return {
				name: "default",
				route: "",
				redirect: "dashboard"
			};
		});

		// Add a router pipeline step that checks whether the user is authenticate to access the route.
		config.addPipelineStep("authorize", AuthenticatePipelineStep);

		// Configure history usage.
		config.options.pushState = ENVIRONMENT.pushState;

		// Configure title generation.
		config.title = document.title;
		router.titleSeparator = " — ";
		router.transformTitle = title => ["List"].includes(title) ? "" : title;
	}
}
