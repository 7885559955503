import { IdentityService } from "company-dashboard/services/identity";
import { autoinject, computedFrom, bindable, bindingMode } from "aurelia-framework";
import { Router, NavModel } from "aurelia-router";
import { ThemeService } from "shared/framework";

/**
 * Represents a menu of the side of the page.
 */
@autoinject
export class AppSideMenu
{
  public constructor(router: Router, themeService: ThemeService, identityService: IdentityService)
  {
    this._identityService = identityService;
    this._router = router;
    this._themeService = themeService;
  }

  private readonly _identityService: IdentityService;
  private readonly _router: Router;
  protected readonly _themeService: ThemeService;

  protected currentParentRoute: string | undefined;

  /**
   * Whether or not the mobile menu is open
   */
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  protected mobileOpen: boolean;

  @computedFrom("_router.currentInstruction")
  protected get navModels(): NavModel[]
  {
    const instructions = this._router.currentInstruction.getAllInstructions();
    const childRouter = instructions[instructions.length - 1].router;

    this.currentParentRoute = this._router.currentInstruction.config.name;

    return childRouter.navigation;
  }

  protected get activeNavIndex(): number | undefined
  {
    return this.navModels.findIndex(navModel => navModel.isActive);
  }

  protected logOut(): void
  {
    this._identityService.identity.logout();
    this._router.navigate("log-ind");
  }

  /**
   * Ensures that the menu closes after a navigation click on mobile platforms
   */
  public onNavClick(): void
  {
    this.mobileOpen = false;
  }
}
