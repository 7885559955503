import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { IValidation, ModalService, ToastService } from "shared/framework";
import { IdentityService } from "company-dashboard/services/identity";
import { EmployeeSignUp, EmployeeService, EmploymentType } from "company-dashboard/model/dashboard/employee";
import { Operation } from "shared/utilities";
import { DateTime } from "luxon";

@autoinject
export class SalaryAndPension
{
	public constructor(
		identityService: IdentityService,
		employeeService: EmployeeService,
		modalService: ModalService,
		toastService: ToastService)
	{
		this.identityService = identityService;
		this._employeeService = employeeService;
		this._modalService = modalService;
		this._toastService = toastService;
	}

	private readonly _employeeService: EmployeeService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	protected readonly identityService: IdentityService;

	protected operation: Operation;

	protected validation: IValidation;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected state: "add" | "edit";

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected employee: EmployeeSignUp;

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected employeeIsLocked: boolean;

	protected readonly employmentTypes = [ new EmploymentType("employee"), new EmploymentType("owner") ];

	@bindable
	protected onNext: () => void;

	@bindable
	protected onBackClick: () => void;

	@bindable
	protected onCloseClick: () => void;

	protected firstLoad: boolean = true;

	protected async employeeChanged(newValue: EmployeeSignUp): Promise<void>
	{
		if (this.firstLoad)
		{
			if (newValue.id != null)
			{
				await this.getCompensationInformation();
			}
			this.firstLoad = false;
		}
	}

	@computedFrom("employee.companyAgreementStartDate")
	protected get minimumValidDateOfMandatoryStart(): DateTime | undefined
	{
		if(this.employee?.companyAgreementStartDate == null ||
			!this.employee.companyAgreementStartDate.isValid)
		{
			return undefined;
		}

		const today = DateTime.local();
		const currentMonth = today.set({ day: 1 }).startOf("day");
		const agreementStartDate = this.employee.companyAgreementStartDate;

		return currentMonth < agreementStartDate
			? agreementStartDate
			: currentMonth;
	}

	protected async getCompensationInformation(): Promise<void>
	{
		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				// Fetch the data
				this.employee = await this._employeeService.getCompensationInformation(
					this.employee.id,
					signal
				);

				this.employeeIsLocked = 
					this.employee.compensationInformation.monthlySalaryLocked && 
					this.employee.compensationInformation.employmentTypeLocked &&
					this.employee.compensationInformation.mandatoryPolicyStartDateLocked &&
					this.employee.compensationInformation.employeeGroupLocked &&
					this.employee.compensationInformation.subsidiaryLocked;

				if (this.employee.fromPayrollSystem)
				{
					this.validation.active = true;
				}
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": `An error occurred.\n${error.message.toString()}`
					}
				)
			}
		})
	}

	protected async updateCompensationInformation(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				await this._employeeService.updateCompensationInformation(this.employee, signal);

				await this._modalService.closeAll("cancel");

				this._toastService.open(
					"success",
					{
						heading: `Oplysninger for ${this.employee.personalInformation.firstName} er blevet ændret.`
					}
				)
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				);
			}
		})
	}

	protected async signUpEmployee(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				await this._employeeService.signUpEmployee(this.employee, signal);

				this.onNext();
			}
			catch (error: any)
			{
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				);
			}
		})
	}
}
