import { textCase } from "shared/utilities";

/**
 * Represents the slug identifying a `EmploymentType`.
 */
export type EmploymentTypeSlug = keyof typeof EmploymentType.values;

/**
 * Represents the type of an employment.
 */
export class EmploymentType
{
  /**
   * Creates a new instance of the type.
   * @param slug The slug identifying the type of the employment.
   */
  public constructor(slug: EmploymentTypeSlug)
  {
    this.slug = textCase(slug, "pascal", "kebab") as any;
    Object.assign(this, EmploymentType.values[this.slug]);
  }

  public slug: EmploymentTypeSlug;
  public name: string;
  public value: number;

  /**
   * Gets the data representing this instance.
   */
  public toJSON(): any
  {
    return this.value;
  }

  /**
   * The supported values.
   */
  public static readonly values =
  {
    "employee":
    {
      name: "Medarbejder",
      value: 1
    },
    "owner":
    {
      name: "Ejer",
      value: 2
    }
  };
}
