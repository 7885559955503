import clone from "clone";
import { DocumentSigningGroup } from "..";

export class SignedDocument
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(
    documentSigningGroup: DocumentSigningGroup,
    signedDocument: string)
  {
    this.signingGroup = documentSigningGroup;
    this.signedDocument = signedDocument;
  }

  public readonly signingGroup: DocumentSigningGroup;

  public readonly signedDocument: string;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }

	/**
	 * Gets the data representing this instance.
	 */
	public toJSON(): any
	{
		return {
      signedDocument: this.signedDocument,
      signingGroup: this.signingGroup.value
		};
	}
}
