import { EmployeeSignUp, EmploymentType } from "company-dashboard/model/dashboard/employee";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ModalService, ToastService } from "shared/framework";
import { Operation } from "shared/utilities";
import { IdentityService } from "company-dashboard/services/identity";

@autoinject
export class SignUpEmployee
{
	public constructor(
		toastService: ToastService,
		modalService: ModalService,
		identityService: IdentityService)
	{
		this._toastService = toastService;
		this._modalService = modalService;
		this._identityService = identityService;
	}

	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	private readonly _identityService: IdentityService;

	protected pensionStructureOperation: Operation;

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected employee: EmployeeSignUp;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected flow: "add-employee" | "place-of-residence" | "personal-information" | "salary-and-pension";

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected employeeFirstName: string | undefined;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected employeeId: number | undefined;

	protected state: "personal-information" | "place-of-residence" | "salary-and-pension" | "administrator-help";

	@bindable
	protected updateEmployeesPage: boolean = false;

	protected attached(): void
	{
		if (this.employeeId != null)
		{
			this.employee = new EmployeeSignUp({ id: this.employeeId, personalInformation: { firstName: this.employeeFirstName } });
		}
		else
		{
			this.employee = new EmployeeSignUp({});
		}
	}

	public flowChanged(newValue: "add-employee" | "personal-information" | "place-of-residence" | "salary-and-pension"): void
	{
		if (newValue === "salary-and-pension" || newValue === "place-of-residence")
		{
			this.state = newValue;
		}
		else
		{
			this.state = "personal-information";
		}
	}

	protected async onCloseClick(): Promise<void>
	{
		if (this.updateEmployeesPage)
		{
			this._toastService.open(
				"success",
				{
					heading: this.employee.personalInformation.firstName,
					body: "blev tilmeldt og vil modtage en mail med instrukser."
				}
			)
		}
		await this._modalService.closeAll("cancel");
	}

	protected async onNext(): Promise<void>
	{
		if (this.state === "personal-information")
		{
			this.employee.capitalizeNames();

			if (this._identityService.identity.company?.hasMandatoryPlan)
			{
				this.state = "place-of-residence";
			}
			else
			{
				if (!this.employee.ownersTaxedPrivately)
				{
					this.employee.compensationInformation.employmentType = new EmploymentType("employee");
				}

				this.state = "salary-and-pension";
			}
		}
		else if (this.state === "place-of-residence")
		{
			if (!this.employee.ownersTaxedPrivately)
			{
				this.employee.compensationInformation.employmentType = new EmploymentType("employee");
			}

			this.state = "salary-and-pension";
		}
		else if (this.state === "salary-and-pension")
		{
			this.updateEmployeesPage = true;

			if (this.flow === "add-employee")
			{
				this.state = "administrator-help";
			}
		}
	}

	protected onBackClick(): void
	{
		if (this.state === "place-of-residence")
		{
			this.state = "personal-information";
		}
		else if (this.state === "salary-and-pension")
		{
			if (this._identityService.identity.company?.hasMandatoryPlan)
			{
				this.state = "place-of-residence";
			}
			else
			{
				this.state = "personal-information";
			}
		}
	}
}
