import { IdentityService } from "company-dashboard/services/identity";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { IValidation, ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";
import { AdministratorService } from "company-dashboard/model/dashboard/company";

@autoinject
export class AcceptInvitation
{
	/**
	 * Creates a new instance of the type.
	 * @param modal The `Modal` instance representing the modal.
	 * @param administratorService The `AdministratorService` instance.
	 * @param identityService The `IdentityService` instance.
	 * @param toastService The `ToastService` instance.
	 */
	public constructor(modal: Modal, administratorService: AdministratorService, identityService: IdentityService, toastService: ToastService, router: Router)
	{
		this._modal = modal;
		this._administratorService = administratorService;
		this._identityService = identityService;
		this._toastService = toastService;
		this._router = router;
	}

	private readonly _modal: Modal;
	private readonly _administratorService: AdministratorService;
	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;
	private readonly _router: Router;
	private passwordReset = false;

	protected validation: IValidation;

	/**
	 * The new password of the user
	 */
	protected password: string;

	/**
	 * The confirmed password
	 */
	protected confirmPassword: string;

	/**
	 * If the user consents to receive marketing information
	 */
	protected receiveMarketingInformation: boolean = false;

	/**
	 * The most recent update operation
	 */
	protected operation: Operation;

	/**
	 * The model for the modal.
	 */
	protected model: { token: string, id: string };

	/**
	 * Called by the framework when the modal is activated.
	 * @param model The model to use for the modal.
	 */
	public activate(model: { token: string, id: string }): void
	{
		this.model = model;
	}

	/**
	 * Called by the framework when the modal is deactivated.
	 * @returns True if the password has been reset.
	 */
	public deactivate(): boolean
	{
		return this.passwordReset;
	}

	/**
	 * Check if the password is 8 characters long
	 * contains atleast a number, and letters
	 */
	protected get passwordValid(): boolean
	{
		if (this.password == null)
		{
			return false;
		}

		if (this.password.length < 8)
		{
			return false;
		}

		if (!/\d/.test(this.password))
		{
			return false;
		}

		if (!isNaN(Number(this.password)))
		{
			return false;
		}

		return true;
	}

	/**
	 * Called when the cancel button is clicked.
	 */
	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	protected async onResetPassword(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.operation != null)
		{
			this.operation.abort();
		}

		// Create and execute the operation
		this.operation = new Operation(async signal => {
			try
			{
				await this._administratorService.acceptInvite(this.password, this.confirmPassword, this.model.token, this.model.id, this.receiveMarketingInformation, signal);

				this.passwordReset = true;

				await this._identityService.initial();

				await this._modal.close();
				this._router.navigate("medarbejdere", { replace: true });
			}
			catch (error)
			{
				this._toastService.open(
					"error",
					{
						"error": error
					}
				)
			}
		})
	}
}
