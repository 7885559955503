import clone from "clone";
import { DateTime } from "luxon";
import { EmployeeStatus } from "./employee-status";

/**
 * The employee instance used in data tables.
 */
export class EmployeeSimple
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.id = data.id;
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.monthlySalary = data.monthlySalary;
    this.companyContributionPercentage = data.companyContribution;
    this.ownContributionPercentage = data.ownContribution;
    this.status = EmployeeStatus.fromJSON(data.status);
    this.agreementStartDate = data.agreementStartDate ? DateTime.fromISO(data.agreementStartDate) : undefined;
    this.agreementEndDate = data.agreementEndDate ? DateTime.fromISO(data.agreementEndDate) : undefined;
    this.missingPersonalInformation = data.missingPersonalInformation ?? false;
    this.missingAddressInformation = data.missingAddressInformation ?? false;
    this.missingCompensationInformation = data.missingCompensationInformation ?? false;
  }

  public readonly id: number;

  public readonly firstName: string;

  public readonly lastName: string;

  public readonly companyContributionPercentage: number;

  public readonly ownContributionPercentage: number;

  public readonly monthlySalary: number;

  public readonly status: EmployeeStatus;

  public readonly agreementStartDate?: DateTime;

  public readonly agreementEndDate?: DateTime;

  public readonly missingPersonalInformation: boolean;

  public readonly missingAddressInformation: boolean;

  public readonly missingCompensationInformation: boolean;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public get fullName(): string
  {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
