import clone from "clone";

export class OnboardingState
{
		/**
		 * Creates a new instance of the type.
		 * @param data The response data from which the instance should be created.
		 */
		public constructor(data: any)
		{
			this.hasFinishedOnboarding = data.hasFinishedOnboarding;
		}

		public readonly hasFinishedOnboarding: boolean;

		/**
		 * Gets a clone of this instance, suitable for editing.
		 */
		public clone(): any
		{
			return clone(this);
		}

		/**
		 * Gets the data representing this instance.
		 */
		public toJSON(): any
		{
			return {
				hasFinishedOnboarding: this.hasFinishedOnboarding
			};
		}
}
