import { autoinject } from "aurelia-framework";
import { PayrollSystemService } from "company-dashboard/model/dashboard/company";
import { PayrollSystem } from "company-dashboard/model/dashboard/shared";
import { IValidation, ModalService, ThemeService, ToastService } from "shared/framework";
import { Operation } from "shared/utilities";

@autoinject
export class ConnectToSalary
{
  public constructor(themeService: ThemeService, modalService: ModalService, toastService: ToastService, payrollSystemService: PayrollSystemService)
  {
    this.themeService = themeService;
		this._modalService = modalService;
		this._toastService = toastService;
		this._payrollSystemService = payrollSystemService;
  }

  protected readonly themeService: ThemeService;
	private readonly _modalService: ModalService;
	private readonly _toastService: ToastService;
	private readonly _payrollSystemService: PayrollSystemService;

	protected salaryKey?: string;

	protected salaryKeyInvalid: boolean = false;

	protected connectedToSalary: boolean;

	protected operation: Operation;

	protected validation: IValidation;

	protected async addSalaryApiToken(): Promise<void>
	{
		if (!await this.validation.validate())
		{
			return;
		}

		// Create and execute the operation
		this.operation = new Operation(async signal =>
		{
			try
			{
				// Hardcoded to start with
				const salaryPayrollSystem = new PayrollSystem("salary");

				await this._payrollSystemService.addPayrollSystemApiToken(this.salaryKey!, salaryPayrollSystem, signal);

				this.connectedToSalary = true;

				await this._modalService.closeAll("cancel");
			}
			catch (error: any)
			{
				if (error.response.status === 400)
				{
					this.salaryKeyInvalid = true;
					this.validation.active = true;
					this._toastService.open(
						"error",
						{
							"message": "Hov, den nøgle ser ikke rigtig ud. Kontroller at den er korrekt og prøv igen."
						}
					);
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": error.message
						}
					);
				}
			}
		})
	}

	protected async onCloseClick(): Promise<void>
	{
		if (this.connectedToSalary)
		{
			this._toastService.open(
				"success",
				{
					body: "Dit Grandhood Dashboard er blevet forbundet med Salary. "
				}
			)
		}
		await this._modalService.closeAll("cancel");
	}
}
