import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";

@autoinject
export class AdministratorExistsService
{
	private readonly _apiClient: ApiClient;

	public constructor(apiClient: ApiClient)
	{
		this._apiClient = apiClient;
	}

	public async exists(administratorEmail: string, signal?: AbortSignal): Promise<boolean>
	{
		var result = await this._apiClient.post(
			"/administrator/exists",
			{
				body: { email: administratorEmail },
				signal
			}
		);

		return result.data.exists;
	}
}
