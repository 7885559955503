import { autoinject, PLATFORM } from "aurelia-framework";
import { RouterConfiguration, Router } from "aurelia-router";
/**
 * Represents the module.
 */
@autoinject
export class SignUpModule
{
	/**
	 * Called to configure the router for the module.
	 * @param config The router configuration associated with the module.
	 * @param router The router associated with the module.
	 */
	public configureRouter(config: RouterConfiguration, router: Router): void
	{
		config.map(
			[
				{
					name: "default",
					route: "",
					redirect: "tilmeld-firma"
				},
				{
					name: "sign-up-company",
					route: "tilmeld-firma",
					moduleId: PLATFORM.moduleName("./modules/sign-up-company/sign-up-company"),
					title: "Tilmeld firma",
					nav: true,
				},
				{
					// It is important this entry is before "agreement-documents" as this entry's route
					// is part of the others route. It is kept separate for now as the "agreement-documents"
					// uses the old signing solution and I did not want to have to change the redirect url
					// in the backend later by adding '*-mitid' to this one.
					name: "agreement-documents-signed",
					route: ["aftaledokumenter/underskrevet", "aftaledokumenter/underskrevet/:modal"],
					moduleId: PLATFORM.moduleName("./modules/agreement-documents-signed/agreement-documents-signed"),
					title: "Underskrevet aftaledokumenter",
					nav: false,
				},
				// { // TODO: Delete this and clean up the code to match the solution
				// 	name: "agreement-documents-mitid",
				// 	route: ["aftaledokumenter-mitid", "aftaledokumenter-mitid/:modal"],
				// 	moduleId: PLATFORM.moduleName("./modules/agreement-documents-mitid/agreement-documents-mitid"),
				// 	title: "Aftaledokumenter",
				// 	nav: false,
				// },
				{
					name: "agreement-documents",
					route: ["aftaledokumenter", "aftaledokumenter/:modal"],
					moduleId: PLATFORM.moduleName("./modules/agreement-documents-mitid/agreement-documents-mitid"),
					title: "Aftaledokumenter",
					nav: true,
				},
				{
					name: "your-dashboard",
					route: "dit-dashboard",
					moduleId: PLATFORM.moduleName("./modules/your-dashboard/your-dashboard"),
					title: "Dit dashboard",
					nav: true,
				}
			]
		);
	}
}
