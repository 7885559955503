import { Router } from "aurelia-router";
import { AgreementDocumentsService } from "company-dashboard/model/sign-up/agreement-documents";
import { autoinject, } from "aurelia-framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";

@autoinject
export class AgreementDocumentsSignedModule extends BaseModule
{
	public constructor(
		agreementDocumentsService: AgreementDocumentsService,
		router: Router)
	{
		super();

		this._agreementDocumentsService = agreementDocumentsService;
		this._router = router;
	}

	private readonly _agreementDocumentsService: AgreementDocumentsService;
	private readonly _router: Router;

	protected failed: boolean = false;

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public async doActivate(): Promise<void>
	{
		await this._agreementDocumentsService.setSignedDocuments();

		this._router.navigate("dit-dashboard");

		return;
	}
}
