import clone from "clone";

export class EmployeeAddress
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.streetName = data?.streetName;
		this.streetNumber = data?.streetNumber;
		this.floorAndSide = data?.floorAndSide;
		this.postalCode = data?.postalCode;
		this.city = data?.city;
		this.country = data?.country;
	}

	public streetName: string;
	public streetNumber: string;
	public floorAndSide: string;
	public postalCode: string;
	public city: string;
	public country: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
