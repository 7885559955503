import { IdentityService } from "company-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { EmployeeSignUp, EmployeesPageResponse } from "..";
import { DateTime } from "luxon";

/**
 * Represents a service that manages employees.
 */
@autoinject
export class EmployeeService
{
	/**
	 * Creates a new instance of the type.
	 * @param apiClient The `ApiClient` instance.
	 * @param identityService The `IdentityService` instance.
	 */
	public constructor(apiClient: ApiClient, identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	/**
	 * Gets all employees visible to the current user.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved with the employees.
	 */
	public async getEmployees(signal?: AbortSignal): Promise<EmployeesPageResponse>
	{
		const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/employees`,
			{
				signal
			}
		);

		return new EmployeesPageResponse(result.data);
	}

	public async deactivateEmployee(employeeId: number, endDate: DateTime, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.company?.id}/employee/${employeeId}/deactivate`,
			{
				body: {
					endDate: endDate
				},
				signal
			}
		);
	}

	public async getAddressInformation(
		id?: number,
		signal?: AbortSignal
	): Promise<EmployeeSignUp>
	{
		if(id == null)
		{
			throw "Employee id cannot be null";
		}

		const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/employee/${id}/update/address-information`,
			{
				signal
			}
		);

		return new EmployeeSignUp(result.data);
	}

	public async getCompensationInformation(
		id?: number,
		signal?: AbortSignal
	): Promise<EmployeeSignUp>
	{
		if(id == null)
		{
			throw "Employee id cannot be null";
		}
		
		const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/employee/${id}/update/compensation-information`,
			{
				signal
			}
		);

		return new EmployeeSignUp(result.data);
	}

	public async getPersonalInformation(
		id?: number,
		signal?: AbortSignal
	): Promise<EmployeeSignUp>
	{
		if(id == null)
		{
			throw "Employee id cannot be null";
		}

		const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/employee/${id}/update/personal-information`,
			{
				signal
			}
		);

		return new EmployeeSignUp(result.data);
	}

	public async signUpEmployee(employee: EmployeeSignUp, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(`/${this._identityService.identity.company?.id}/employee/register`,
			{
				body: employee.toJSON(this._identityService.identity.company!.hasMandatoryPlan),
				signal
			}
		);
	}

	public async getSignUpEmployeeData(signal?: AbortSignal): Promise<EmployeeSignUp>
	{
		const result = await this._apiClient.get(`/${this._identityService.identity.company?.id}/employee/register`,
			{
				signal
			}
		);

		return new EmployeeSignUp(result.data);
	}

	public async updateAddressInformation(employee: EmployeeSignUp, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(`/${this._identityService.identity.company?.id}/employee/${employee.id}/update/address-information`,
			{
				body: employee.address,
				signal
			}
		);
	}

	public async updateCompensationInformation(employee: EmployeeSignUp, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(`/${this._identityService.identity.company?.id}/employee/${employee.id}/update/compensation-information`,
			{
				body: employee.compensationInformation.toJSON(),
				signal
			}
		);
	}

	public async updatePersonalInformation(employee: EmployeeSignUp, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(`/${this._identityService.identity.company?.id}/employee/${employee.id}/update/personal-information`,
			{
				body: employee.personalInformation.toJSON(),
				signal
			}
		);
	}
}
