import { AgreementDocument } from "company-dashboard/model/dashboard/company";
import { Router, Redirect } from "aurelia-router";
import { AgreementDocumentService } from "company-dashboard/model/dashboard/company/services/agreement-document-service";
import { AgreementDocumentsService } from "company-dashboard/model/sign-up/agreement-documents";
import { autoinject, } from "aurelia-framework";
import { ModalService, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";
import { IdentityService } from "company-dashboard/services/identity";

// REUSING THE SAME MODAL FOR THE EXISTING AGREEMENT DOCUMENTS, SO THAT WE DO NOT COPY CODE.
// ALSO THIS JUST NEEDS TO BE MOVED, WHEN THE CURRENT AGREEMENT DOCUMENTS PAGE RETIRES OR RATHER
// THE CODE IN HERE MUST BE MOVED TO THE CURRENT AGREEMENT DOCUMENTS PAGE
import { AdminSignUpFromBroker } from "../agreement-documents/modals/admin-sign-up-from-broker/admin-sign-up-from-broker";

interface AgreementDocumentsParams
{
	modal: "opret-adgangskode" | undefined;
	token: string | undefined;
	id: string | undefined;
}

@autoinject
export class AgreementDocumentsModule extends BaseModule
{
	public constructor(
		agreementDocumentsService: AgreementDocumentsService,
		agreementDocumentService: AgreementDocumentService,
		toastService: ToastService,
		modalService: ModalService,
		identityService: IdentityService,
		router: Router)
	{
		super();

		this._agreementDocumentsService = agreementDocumentsService;
		this._agreementDocumentService = agreementDocumentService;
		this._toastService = toastService;
		this._modalService = modalService;
		this._identityService = identityService;
		this._router = router;

		this._contructed = true;
	}

	private readonly _agreementDocumentsService: AgreementDocumentsService;
	private readonly _agreementDocumentService: AgreementDocumentService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	private readonly _identityService: IdentityService;
	private readonly _router: Router;
	private readonly _contructed;

	protected failed: boolean = false;

	/**
	 * The items to represent in the list of agreement documents
	 */
	protected agreementDocuments: AgreementDocument[] | undefined;

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	protected documentToSignOperation: Operation;

	protected async onReadDocumentClick(s3Key: string): Promise<void>
	{
		let blob = await this._agreementDocumentService.getAgreementDocument(s3Key);

		let anchor = document.createElement("a");
		anchor.href = URL.createObjectURL(blob);
		anchor.download = s3Key;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
		URL.revokeObjectURL(anchor.href);
	}

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public async doActivate(params: AgreementDocumentsParams): Promise<void>
	{
		this._modalService.closeAll("navigation");

		if (
			params.modal === "opret-adgangskode" &&
			(params.token == null || params.id == null))
		{
			new Redirect("log-ind", { replace: true });
		}
		else if (
			params.modal === "opret-adgangskode" &&
			params.token != null &&
			params.id != null)
		{
			this._identityService.unauthenticate();
			const passwordDidReset = await this._modalService.open(AdminSignUpFromBroker, { token: params.token, id: params.id }).promise;

			if (passwordDidReset)
			{
				await this._identityService.initial();
				this._router.navigate("aftaledokumenter", { replace: true });
			}
		}
		else
		{
			this.update();
		}
	}

	protected async onNextClick(): Promise<void>
	{
		this.failed = false;

		await this._agreementDocumentsService.setDocumentsRead();

		if (this.documentToSignOperation != null)
		{
			this.documentToSignOperation.abort();
		}

		// Create and execute the operation
		this.documentToSignOperation = new Operation(async signal =>
		{
			try
			{
				// Fetch the data
				const signingUrl = await this._agreementDocumentsService.getDocumentsSigningUrl(signal);

				this._router.navigate(signingUrl);
			}
			catch (error: any)
			{
				this.failed = true;
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				)
			}
		})
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	protected update(): void
	{
		// Returns if the object is not contructed.
		// This is needed because the `observable` decorator called the change handler when the
		// initial property value is set, which happens before the constructor is called
		if (!this._contructed)
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			this.failed = false;

			try
			{
				// Fetch the data
				const result = await this._agreementDocumentsService.getAgreementDocuments(signal);

				this.agreementDocuments = result;
			}
			catch (error: any)
			{
				this.failed = true;
				this._toastService.open(
					"error",
					{
						"message": error.message
					}
				)
			}
		})
	}
}
