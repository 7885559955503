import clone from "clone";

export class EmployeePersonalInformation
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.socialSecurityNumber = data.cpr;
		this.email = data.email;
		this.phoneNumber = data.phoneNumber;
	}

	public firstName: string;
	public lastName: string;
	public socialSecurityNumber: string;
	public email: string;
	public phoneNumber: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}

	/**
	 * Gets the data representing this instance.
	 */
	public toJSON(): any
	{
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			cpr: this.socialSecurityNumber,
			email: this.email,
			phoneNumber: this.phoneNumber
		};
	}
}
